<template>
  <div class="req-temp-form">
    <form @submit.prevent="createTemplate" enctype="multipart/form-data">
    <p>{{ instructionText }}</p>
      <vs-row id="profile-page" v-if="templateType == 'api'">
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse" class="pb-8">
                <div slot="header">
                  <h3 class="text-left card-title font-normal">Template name <span class="text-2xl mid-blue">*</span></h3>
                </div>
                <vs-row>
                  <vs-col vs-w="4">
                    <label class="w-full">This will display on your dashboard <span>*</span></label>
                    <vs-input type="text" v-model="templateName" v-validate="'required'" name="templateName" class="lg:w-2/4 md:w2/4 sm:w-1/2 w-full" id="templateName" />
                    <vs-col vs-type="flex" vs-align="center" vs-w="12">
                      <span class="text-danger text-sm mt-2" v-show="errors.has('templateName')">{{ errors.first("templateName") }}</span>
                    </vs-col>
                  </vs-col>
                  <vs-col vs-w="8">
                    <p><label>Description</label></p>
                    <vs-textarea type="text" v-model="templateDescription" name="templateDescription" class="lg:w-2/4 md:w2/4 sm:w-1/2 w-full" id="templateDescription"></vs-textarea>
                    <vs-col vs-type="flex" vs-align="center" vs-w="12">
                      <span class="text-danger text-sm mt-2" v-show="errors.has('templateDescription')">{{ errors.first("templateDescription") }}</span>
                    </vs-col>
                  </vs-col>
                  <vs-col vs-w="4" v-if="editFlag">
                    <label>Template ID</label>
                    <p class="mt-2">{{ templateId }}</p>
                  </vs-col>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>
      </vs-row>
      <vs-row id="profile-page" class="mt-5 sim-adv" v-else>
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header" class="flex justify-between">
                  <h3 class="text-left card-title font-normal">Basics <span class="text-2xl mid-blue">*</span></h3>
                  <span class="mr-10 mb-0 pt-2" v-if="editFlag">{{ templateId }}</span>
                </div>
                <vs-row>
                  <vs-col vs-w="5">
                    <div class="mb-6" v-if="readyToRender">
                      <label class="w-full text-base font-light">Name to display on dashboard</label>
                      <div class="input-grp">
                        <vs-input type="text" v-model="templateName" v-validate="'required'" name="templateName" id="templateName"/>
                        <span class="text-danger text-sm mt-2" v-if="errors.has('templateName')">{{ errors.first("templateName") }}</span>
                      </div>
                    </div>
                    <div class="mb-4" v-if="readyToRender && defaultPlans.length > 0">
                      <PaymentOption
                        :isSubmitted="isSubmitted"
                        :paymentOptionData="paymentOptionData"
                        :paymentDataDefault="paymentDataDefault"
                        @checkDirty="checkDirty"
                        :editFlag="editFlag"
                        :productConfigType="productConfigType"
                        :planOptions="defaultPlans"
                        :templateConfigType="templateConfigType"
                        @updateProductConfig="changeProductConfig"
                      />
                    </div>
                    <div class="mb-4" v-if="readyToRender && ['pay-now'].includes(templateConfigType)">
                      <label class="w-full text-base font-light">Select a bank account to receive payments into</label>
                      <div class="input-grp">
                        <vs-select
                          v-model="selectedAccount"
                          name="linkedAccount"
                          id="linkedAccount"
                          class="w-full selectExample"
                          v-validate="'required'"
                          @change="changeConnector"
                          :placeholder="'Select account'"
                        >
                          <vs-select-item
                            :key="index"
                            :value="item.bankId"
                            :text="item.displayName ? item.displayName : item.accountName"
                            v-for="(item, index) in bankList"
                          />
                        </vs-select>
                        <span class="text-danger text-sm" v-show="errors.has('linkedAccount')">{{ errors.first("linkedAccount") }}</span>
                      </div>
                    </div>
                    <div class="flex mt-8" v-if="readyToRender && ['pay-now', 'pay-later'].includes(templateConfigType)">
                      <vs-switch v-model="automatedReminder" name="automatedReminder"></vs-switch>
                      <label class="w-full text-base font-light ml-5">Send automated reminder for unpaid requests</label>
                    </div>
                  </vs-col>
                  <vs-col vs-w="7">
                    <div v-if="readyToRender && productConfigType == 'THREE_PARTY'">
                      <CustomTextBlock :customTextBlockData="customTextBlockData" :templateConfigType="templateConfigType" @checkDirty="checkDirty"></CustomTextBlock>
                    </div>
                  </vs-col>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <disbursement
          v-if="renderDisbursement && selectedProduct"
          :disbursement="disbursement"
          :merchant-banks="bankList"
          :external-accounts="savedExternalAccounts"
          :linked-account="selectedAccount"
          :is-submitted="isSubmitted"
          :selected-plan="selectedProduct"
          :template-config-type="templateConfigType"
          @changeLinkedAccount="changeConnector"
          @changeRemittanceSettings="changeRemittanceSettings"
        />

        <debit v-if="displayDebit" :app-name="appName" :debit="debit" :merchant-banks="bankList" :is-submitted="isSubmitted" />

        <remittance-advice
          v-if="renderRemittance && ['advance', 'simple'].includes(templateType) && ['direct', 'pay-later', 'pay-now'].includes(templateConfigType)"
          :isSubmitted="isSubmitted"
          :remittance-advice="remittanceAdvice"
          :templateConfigType="templateConfigType"
          :default-email="defaultRemittanceEmail"
          @checkDirty="checkDirty"
        />

        <notification
          v-if="readyToRender && ['advance', 'simple'].includes(templateType)"
          :isSubmitted="isSubmitted"
          :notification="notifications"
          :prNotificationsCheckbox="prNotificationsCheckbox"
          :paymentOptionData="paymentOptionData"
          :templateConfigType="templateConfigType"
          :webhookUrlData="webhookUrl"
          :webhookTokenData="webhookToken"
          @changeNotification="notifyChange"
          @checkDirty="checkDirty"
          @updateWebhookUrl="updateWebhookUrl"
          @updateWebhookToken="updateWebhookToken"
        />

        <vx-card class="accordin-card mt-5" v-if="readyToRender && productConfigType == 'THREE_PARTY'">
          <vs-collapse accordion>
            <vs-collapse-item ref="headerCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">Branding</h3>
              </div>

              <p class="mb-8">Configure simple branding elements to display within payment requests and hosted payment pages using this template</p>
              <page-header
                :headerData="header"
                :logos="logoLibrary"
                :isSubmitted="isSubmitted"
                :resetEmailMask.sync="resetEmailMask"
                :defaultEmailMask="defaultEmailMask"
                @checkToggle="partnerToggle"
                @changeHeader="headerChange"
                @checkDirty="checkDirty"
              />
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <redirection
          v-if="readyToRender && productConfigType == 'THREE_PARTY'"
          :redirectData="redirectData"
          :paymentOptionData="paymentOptionData"
          :paymentDataDefault="paymentDataDefault"
          :editFlag="editFlag"
          ref="redirection"
          :isSubmitted="isSubmitted"
          @checkDirty="checkDirty"
        />

        <user-permission
          v-if="readyToRender"
          ref="permission"
          :editFlag="editFlag"
          :merchant-users="merchantUsers"
          :authoriseAll="authoriseAll"
          :isSubmitted="isSubmitted"
          :templateConfigType="templateConfigType"
          @checkDirty="checkDirty"
          @toggleChange="handleUserToggle"
        />

      </vs-row>
      <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

      <pop-up :errorsList="errors" form-name="RequestTemplateForm"></pop-up>

      <vs-row class="mt-10" v-if="readyToRender">
        <div class="actions mt-5 text-right move-left">
          <vs-button v-if="editFlag" size="large" v-round class="only-border-btn mr-8" type="border" @click="fetchPendingPR">Delete Template</vs-button>
          <vs-button size="large" v-if="templateType == 'api'" v-round :disabled="!validateForm || errors.items.length > 0" @click="createApiTemplate">{{ saveTemplate }}</vs-button>
          <vs-button v-else size="large" v-round :disabled="!validateForm || errors.items.length > 0" @click="createTemplate">{{ saveTemplate }}</vs-button>
          <a color="danger" @click="onCancel" class="ml-8">Cancel</a>
        </div>
      </vs-row>
    </form>
    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      :found="warningFound"
      @handleDeleteClick="delActions"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";
import CustomFields from "./customFields.vue";
import CustomTextBlock from "./CustomTextBlock.vue";
import DeletePopup from "@/views/components/DeletePopup";
import Debit from "./Debit.vue";
import Disbursement from "./Disbursement.vue";
import LeavePopup from "@/views/components/LeavePopup";
import Notification from "./Notification.vue";
import PageHeader from "./Header.vue";
import PaymentOption from "./PaymentOption.vue";
import PopUp from "@/views/components/ValidationPopup.vue";
import Redirection from "./redirection.vue";
import RemittanceAdvice from "./RemittanceAdvice.vue";
import UserPermission from "./UserPermission.vue";
import { sentryLog } from "../../../../../helper/sentryLog";
import _ from "lodash";

const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      templateDescription: "template description",
      linkedAccount: "linked account",
    },
  },
};
Validator.localize(dictionary);

export default {
  mixins: [leavePopUpMixin],
  components: {
    CustomFields,
    CustomTextBlock,
    DeletePopup,
    Debit,
    Disbursement,
    LeavePopup,
    Notification,
    PageHeader,
    PaymentOption,
    PopUp,
    Redirection,
    RemittanceAdvice,
    UserPermission
  },

  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      defaultLoadedConnector: {},
      defaultPrNotification: [],
      redirectData: {
        success: {
          heading: "Thank you again!",
          btntext: "Tap here to go now",
          message: "Thanks for paying, head back to our website to do more business",
          link: (process.env.VUE_APP_NAME == "FlipPay") ? "https://flippay.com.au" : "https://rellopay.io", // TODO: This should be moved to settings collection
          enableRedirect: false,
        },
      },
      templateType: "simple",
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      automatedReminder: false,
      templateName: "",
      templateDescription: "",
      templateId: "",
      linkedAccount: {},
      selectedAccount: "",
      bankList: [],
      displayName: "",
      defaultHeader: {},
      header: {
        companyName: "",
      },
      logoLibrary: [],
      merchantId: "",
      isSubmitted: false,
      // custom text block
      customTextBlockData: {
        body: "",
        allowOverride: true,
      },
      // Payment options *
      paymentOptionData: {
        requestType: "",
        requestOptions: [],
        recurringSchedule: {
          period: "week", // month week data etc
          frequency: "", // number 1,2
          startDate: "",
          endDate: "",
          endType: "", // by , after
          endPayments: "", // number 1 /2
        },
        payLaterPlan: [],
        planDisplayName: "",
      },
      productConfigType: "THREE_PARTY",
      autoDebit: true,
      paymentDataDefault: JSON.parse(localStorage.getItem("user")).userType == "admin" ? JSON.parse(localStorage.getItem("user")) : JSON.parse(localStorage.getItem("user")).partner,
      globalDefaultOverride: true,
      notifications: {
        options: ["REQUEST_SENDER"],
        prNotification: [],
      },
      prNotificationsCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      pageNotifications: {},
      pagePrNotify: {
        forEmail: "",
        forDashboard: "",
      },
      id: "",
      editHeader: {},
      saveTemplate: "Create Template",
      editFlag: false,
      warningContent: "",
      deleteWhat: `template`,
      buttonText: `Delete`,
      popupDelActive: false,
      warningFound: false,
      childFormDirty: false,
      planOptions: [],
      defaultPlans: [],
      templateConfigType: "",
      template: "",
      selectedPlan: "",
      partnerRequestOptions: [],
      instructionText: "",
      readyToRender: false,
      remittanceAdvice: {
        allowAdd: false,
        allowOverride: false,
        bankAccountDefault: true,
        emailAddress: [],
      },
      renderRemittance: false,
      disbursement: {
        type: "merchantAccount",
        allowAnyBank: false,
        allowExternalAccount: false,
        allowMerchantAccount: false,
      },
      renderDisbursement: false,
      savedExternalAccounts: [],
      selectedProduct: null,
      renderDebit: true,
      debit: {
        bankId: null,
        allowOverride: false,
      },
      webhookUrl: "",
      webhookToken: "",
      merchantUsers: [],
      authoriseAll: false,
      defaultEmailMask: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay",
      initialLoad: true,
    };
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },

    defaultRemittanceEmail() {
      let email = "";

      if (this.linkedAccount && this.linkedAccount.bankId) {
        let bank = {};

        if (this.disbursement && this.disbursement.type == "externalAccount") {
          bank = this.savedExternalAccounts.find((item) => item.bankId == this.linkedAccount.bankId);
        } else {
          bank = this.bankList.find((item) => item.bankId == this.linkedAccount.bankId);
        }

        if (bank && bank.remittanceAdviceEmail) {
          email = bank.remittanceAdviceEmail.join(", ");
        }
      }

      return email;
    },

    displayDebit() {
      let display = (this.templateConfigType == "direct" && this.selectedProduct && this.selectedProduct.autoDebit);

      if (!display) {
        this.debit = {
          bankId: null,
          allowOverride: false
        };
      }

      return display;
    }
  },

  created() {
    this.id = this.$route.params.id;
    this.templateType = "advance";

    if (this.id) {
      this.saveTemplate = "Update Template";
      this.editFlag = true;
    }
  },

  methods: {
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("paymentRequestTemplate", [
      "createRequestTemplate",
      "deleteRequestTemplateById",
      "fetchRequestTemplateByIdandStatus",
      "fetchRequestTemplateByIdOnMerchant",
      "updateRequestTemplateById",
    ]),
    ...mapActions("logo", ["fetchLogosByMerchantId"]),
    ...mapActions("merchantGlobalSetting", ["fetchSettingsByMerchantId", "fetchNotification"]),
    ...mapActions("partner", ["fetchPartnerStaff", "getPaymentPlanByMerchantId"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("savedExternalAccount", ["fetchSavedExternalAccountsForDropdown"]),

    resetEmailMask() {
      this.header.emailMask = this.defaultHeader.emailMask;
    },

    async getPaylaterPlan() {
      this.planOptions = this.planOptions.filter((item) => !(item.disburseTo == "external" && !item.editDisbursement.merchant && !item.editDisbursement.customer));

      if (this.editFlag) {
        this.selectedPlan = this.planOptions.find((element) => element.customPlanId == this.paymentOptionData.payLaterPlan);

        if (this.selectedPlan) {
          this.productConfigType = this.selectedPlan.productConfigType;
          this.autoDebit = this.selectedPlan.autoDebit;
          this.selectedProduct = this.selectedPlan;
        }
      }
    },

    async getConnectorsList(partnerId) {
      await this.fetchBanksAccountByPartnerId(partnerId).then((result) => {
        if (result) {
          const banksList = result.data.data;
          this.defaultLoadedConnector = result.data.data;
          this.bankList = banksList.filter(item => item.isUsedForSettlement).map((item) => {
            return {
              bankId: item._id,
              displayName: item.displayName ? item.displayName : item.accountName,
              remittanceAdviceEmail: item.remittanceAdviceEmail ? [item.remittanceAdviceEmail] : []
            };
          });
        }

        this.renderDisbursement = ["direct", "pay-later"].includes(this.templateConfigType);
      }).catch((ex) => {
        this.$vs.notify({
          title: "Linked account",
          text: "We are unable to process your request at this time.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
      });
    },

    async fetchTemplateDetails() {
      await this.fetchRequestTemplateByIdOnMerchant(this.id).then((result) => {
        this.template = result.data.data[0];
        this.templateType = this.template.templateType;
        this.redirectData = this.template.redirect;
        this.templateName = this.template.templateName;
        this.templateDescription = this.template.description;
        this.templateId = this.template.requestTemplateId;
        this.linkedAccount = this.template.linkedAccount ? this.template.linkedAccount : {};
        this.selectedAccount = (this.linkedAccount && this.linkedAccount.bankId) ? this.linkedAccount.bankId : "";
        this.webhookUrl = this.template.webhookUrl || "";
        this.webhookToken = this.template.webhookToken || "";

        let headerFlag = this.template.header.isDefaultHeader;
        let selectedCompanyLogo = "";
        let selectedCompanyImage = "";
        let selectedPartnerLogo = "";
        let selectedPartnerImage = "";

        if (headerFlag) {
          selectedCompanyLogo = this.defaultHeader.companyLogo ? this.defaultHeader.companyLogo : undefined;
          selectedCompanyImage = this.defaultHeader.image ? this.defaultHeader.image : undefined;
          selectedPartnerLogo = this.defaultHeader.partnerLogo ? this.defaultHeader.partnerLogo : undefined;
          selectedPartnerImage = this.defaultHeader.partnerimage ? this.defaultHeader.partnerimage : undefined;
        } else {
          selectedCompanyLogo = this.template.header.companyLogo ? this.template.header.companyLogo._id : undefined;
          selectedCompanyImage = this.template.header.companyLogo ? this.template.header.companyLogo.logoImage : undefined;
          selectedPartnerLogo = this.template.header.partnerLogo ? this.template.header.partnerLogo._id : undefined;
          selectedPartnerImage = this.template.header.partnerLogo ? this.template.header.partnerLogo.logoImage : undefined;
        }

        this.editHeader = {
          useRequestSenderDetails: this.$lodashHelper.checkObjectKey(this.template.header, "useRequestSenderDetails", false),
          isDefaultHeader: this.template.header.isDefaultHeader,
          abn: headerFlag ? this.defaultHeader.abn : this.template.header.abn,
          companyName: headerFlag ? this.defaultHeader.companyName : this.template.header.companyName,
          companyEmail: headerFlag ? this.defaultHeader.companyEmail : this.template.header.companyEmail,
          address: headerFlag ? this.defaultHeader.address : this.template.header.address,
          companyLogo: selectedCompanyLogo,
          image: selectedCompanyImage,
          partnerLogo: selectedPartnerLogo,
          partnerimage: selectedPartnerImage,
          partnerLogoEnabled: headerFlag ? this.defaultHeader.partnerLogoEnabled : this.template.header.partnerLogoEnabled,
          phone: headerFlag ? this.defaultHeader.phone : this.template.header.phone,
          website: headerFlag ? this.defaultHeader.website : this.template.header.website,
          emailMask: headerFlag ? (this.defaultHeader.emailMask || this.defaultEmailMask) : (this.template.header.emailMask || this.defaultEmailMask),
        };

        this.header = { ...this.editHeader };
        this.customTextBlockData = this.template.customText;
        this.pageNotifications = this.template.notifications;

        if (this.pageNotifications && this.pageNotifications.prNotification) {
          this.pageNotifications.prNotification = this.defaultPrNotification.map((item) => {
            const statusNotification = this.template.notifications.prNotification.find((res) => res.status == item.status);

            if (statusNotification) {
              item.forEmail = statusNotification.forEmail;
            }

            return item;
          });

          this.notifications = { ...this.pageNotifications };
        }

        this.pagePrNotify.forEmail = this.beChecked(this.template.notifications.prNotification, "forEmail");
        this.pagePrNotify.forDashboard = this.beChecked(this.template.notifications.prNotification, "forDashboard");
        this.prNotificationsCheckbox = { ...this.pagePrNotify };

        if (!this.template.paymentOptions[0].payLaterPlan) {
          this.template.paymentOptions[0].payLaterPlan = [];
        }

        if (!this.template.paymentOptions[0].recurringSchedule) {
          this.template.paymentOptions[0].recurringSchedule = {
            period: "week", // month week data etc
            frequency: "", // number 1,2
            startDate: "",
            endDate: "",
            endType: "", // by , after
            endPayments: "", // number 1 /2
          };
        }

        this.paymentOptionData = this.template.paymentOptions[0];
        this.paymentDataDefault.requestTypes = [this.paymentOptionData.requestType];
        this.remittanceAdvice = {
          allowAdd: this.$lodashHelper.checkObjectKey(this.template.remittanceAdvice, "allowAdd", false),
          allowOverride: this.$lodashHelper.checkObjectKey(this.template.remittanceAdvice, "allowOverride", false),
          bankAccountDefault: this.$lodashHelper.checkObjectKey(this.template.remittanceAdvice, "bankAccountDefault", true),
          emailAddress: this.$lodashHelper.checkObjectKey(this.template.remittanceAdvice, "emailAddress", []),
        };

        const defaultDisbursement = { ...this.disbursement };
        this.disbursement = this.$lodashHelper.checkObjectKey(this.template, "disbursement", defaultDisbursement);

        const defaultDebit = { ...this.debit };
        this.debit = this.$lodashHelper.checkObjectKey(this.template, "debit", defaultDebit);
        this.renderRemittance = (this.disbursement && this.disbursement.type && this.disbursement.type == "customer") ? false : true;
        this.userAccess = this.$lodashHelper.checkObjectKey(this.template, "userAccess", []);
        this.userAccess.forEach((element) => {
          const objIndex = this.merchantUsers.findIndex((obj) => obj._id == element.userId);

          if (objIndex >= 0) {
            this.merchantUsers[objIndex].isAuthorisedToUse = true;
          }
        });

        this.authoriseAll = this.$lodashHelper.checkObjectKey(this.template, "authoriseAll", false);
        this.automatedReminder = this.$lodashHelper.checkObjectKey(this.template, "automatedReminder", false);
      }).catch((ex) => {
        this.$vs.notify({
          title: "Request template",
          text: ex.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
        if (ex.response.status === 404) {
          this.$router.push({ name: "payment-request-template" });
        }
      });
      this.$refs.templateCollapse.maxHeight = "none !important";
    },

    async createApiTemplate() {
      const valid = await this.$validator.validate();

      if (this.errors.items.length > 0) {
        return false;
      }

      if (valid) {
        const formData = new FormData();

        if (this.id) {
          formData.append("templateId", this.id);
        }

        formData.append("merchantId", this.partnerId);
        formData.append("partnerId", this.merchantId);
        formData.append("templateName", this.templateName);
        formData.append("description", this.templateDescription);
        formData.append("templateType", this.templateType);
        formData.append("requestType", "template");
        formData.append("webhookUrl", this.webhookUrl);
        formData.append("webhookToken", this.webhookToken);

        let obj = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };

        this.$vs.loading();
        this.createRequestTemplate(obj).then((result) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: result.data.title,
            text: result.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          localStorage.removeItem("prNotification");
          localStorage.removeItem("prCheckbox");
          this.isSaved = true;
          this.$router.push({ name: "payment-request-template" });
        }).catch((ex) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Request template",
            text: ex.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
      }
    },

    async createTemplate() {
      this.isSubmitted = true;
      const valid = await this.$validator.validate();

      if (this.paymentOptionData.requestType == "") {
        this.$refs.templateCollapse.maxHeight = "none !important";
        return false;
      }

      if (this.errors.items.length) {
        this.isSubmitted = false;
        return false;
      }

      if (!valid) {
        this.$refs.templateCollapse.maxHeight = "none !important";
        return false;
      } else {
        const formData = new FormData();

        if (this.id) {
          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("editedByAdmin", this.user.adminId);
          }

          formData.append("templateId", this.id);
        }

        if (!this.id) {
          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("createdByAdmin", this.user.adminId);
          }
        }

        formData.append("merchantId", this.partnerId);
        formData.append("partnerId", this.merchantId);
        formData.append("templateName", this.templateName);
        formData.append("description", this.templateDescription);
        formData.append("linkedAccount", JSON.stringify(this.linkedAccount));
        formData.append("productConfigType", this.productConfigType);
        formData.append("webhookUrl", this.webhookUrl);
        formData.append("webhookToken", this.webhookToken);

        // making plans as array
        this.paymentOptionData.payLaterPlan = Array.isArray(this.paymentOptionData.payLaterPlan) ? this.paymentOptionData.payLaterPlan : [this.paymentOptionData.payLaterPlan];
        const selectedPlan = this.planOptions.filter((element) => { return (element.customPlanId == this.paymentOptionData.payLaterPlan[0]) });

        if (selectedPlan[0] && selectedPlan[0].productName) {
          this.paymentOptionData.planDisplayName = selectedPlan[0].productName;
        }

        // payment options
        formData.append("paymentOptions", JSON.stringify(this.paymentOptionData));

        if (this.notifications.multipleEmailAddress && this.notifications.multipleEmailAddress.length) {
          this.notifications.multipleEmailAddress = this.notifications.multipleEmailAddress.map(item => { return item.email || item });
        }

        formData.append("notifications", JSON.stringify(this.notifications));

        if (this.productConfigType == "THREE_PARTY") {
          let header = this.header;

          if (this.header.isDefaultHeader) {
            header = {
              useRequestSenderDetails: this.header.useRequestSenderDetails,
              isDefaultHeader: true
            };
          }

          formData.append("header", JSON.stringify(header));
          formData.append("companyLogo", this.header.companyLogo);
          formData.append("partnerLogo", this.header.partnerLogo);
          formData.append("customText", JSON.stringify(this.customTextBlockData));

          if (this.redirectData) {
            formData.append("redirect", JSON.stringify(this.redirectData));
          }

          formData.append("customFields", JSON.stringify([]));
        }

        formData.append("requestType", "template");
        formData.append("templateType", this.templateType);

        if (["direct", "pay-now", "pay-later"].includes(this.templateConfigType)) {
          if (["direct", "pay-later"].includes(this.templateConfigType)) {
            formData.append("disbursement", JSON.stringify(this.disbursement));
          }

          if (!this.remittanceAdvice.bankAccountDefault && this.remittanceAdvice.emailAddress && this.remittanceAdvice.emailAddress.length) {
            this.remittanceAdvice.emailAddress = this.remittanceAdvice.emailAddress.map(item => { return item.email || item });
            formData.append("remittanceAdvice", JSON.stringify(this.remittanceAdvice));
          }
        }

        if (this.displayDebit) {
          formData.append("debit", JSON.stringify(this.debit));
        }

        const userAccess = this.merchantUsers.filter((item) => item.isAuthorisedToUse).map((item) => {
          return {
            userId: item._id
          }
        });

        formData.append("authoriseAll", this.authoriseAll);
        formData.append("userAccess", JSON.stringify(userAccess));

        if (["pay-now", "pay-later"].includes(this.templateConfigType)) {
          formData.append("automatedReminder", this.automatedReminder);
        }

        let obj = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          }
        };

        this.$vs.loading();
        this.createRequestTemplate(obj).then((result) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: result.data.title,
            text: result.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          localStorage.removeItem("prNotification");
          localStorage.removeItem("prCheckbox");
          this.isSaved = true;
          this.$router.push({ name: "payment-request-template" });
        }).catch((ex) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Request template",
            text: ex.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
      }
    },

    getLogos() {
      this.fetchLogosByMerchantId(this.merchantId).then((res) => {
        this.logoLibrary = res.data.data;
      });
    },

    async getNotifications() {
      await this.fetchNotification().then(async (res) => {
        if (res && res.data.data.prNotification) {
          this.defaultPrNotification = [...res.data.data.prNotification];
          this.notifications.requestSenderEmail = true;
          this.notifications.prNotification = res.data.data.prNotification;
          this.prNotificationsCheckbox.forEmail = this.beChecked(res.data.data.prNotification, "forEmail");
          this.prNotificationsCheckbox.forDashboard = this.beChecked(res.data.data.prNotification, "forDashboard");
          localStorage.setItem("prNotification", JSON.stringify(res.data.data.prNotification));
          localStorage.setItem("prCheckbox", JSON.stringify(this.prNotificationsCheckbox));
        }
      }).catch((ex) => {
        this.$vs.notify({
          title: "Notification",
          text: "We are unable to process your request at this time.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
      });
    },

    async getGlobalSetting() {
      await this.fetchSettingsByMerchantId()
        .then(async (res) => {
          if (res.data.data.companyHeader) {
            this.header = res.data.data.companyHeader;
            this.header.isDefaultHeader = true;
            this.header.partnerLogoEnabled = this.header.partnerLogoEnabled ? this.header.partnerLogoEnabled : false;
            this.header.image = this.header.companyLogo ? this.header.companyLogo.logoImage : undefined;
            this.header.companyLogo = this.header.companyLogo ? this.header.companyLogo._id : undefined;
            this.header.partnerimage = this.header.partnerLogo ? this.header.partnerLogo.logoImage : undefined;
            this.header.partnerLogo = this.header.partnerLogo ? this.header.partnerLogo : undefined;
            this.header.emailMask = this.header.emailMask || this.defaultEmailMask;
            this.defaultHeader = this.header;
          } else {
            this.header.isDefaultHeader = true;
            this.header.emailMask = this.defaultEmailMask;
            this.defaultHeader.isDefaultHeader = true;
          }

          this.defaultHeader = { ...this.defaultHeader, useRequestSenderDetails: false };

          if (this.id) {
            await this.fetchTemplateDetails();
          } else {
            this.renderRemittance = true;
            this.handleUserToggle(true);
          }

          if (["direct", "pay-later"].includes(this.templateConfigType)) {
            this.getPaylaterPlan();
          }

          this.getTemplateType();
          this.templateConfig();
          this.getSavedExternalAccounts();
          this.readyToRender = true;
        })
        .catch((ex) => {
          this.$vs.notify({
            title: "Missing default settings",
            text: ex.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            fixed: true
          });
          this.$router.push({ name: "payment-request-template" });
        });
    },

    beChecked(value, field) {
      return value.every((el) => el[field]);
    },

    headerChange(e) {
      if (e) {
        this.header = { ...this.defaultHeader };
      } else {
        if (this.editHeader && this.editHeader.isDefaultHeader == false) {
          this.header = { ...this.editHeader };
        } else {
          this.header = { emailMask: "" };
          this.header.isDefaultHeader = e;
        }
      }
      this.partnerToggle();
    },

    partnerToggle() {
      this.childFormDirty = true;
      this.$refs.headerCollapse.maxHeight = "none !important";
    },

    formValidation() {
      return !this.errors.any();
    },

    notifyChange(e) {
      if (!e.includes("OTHER")) {
        this.notifications.multipleEmailAddress = [];
      }

      this.childFormDirty = true;
    },

    changeConnector(val) {
      this.linkedAccount = null;

      if (val && typeof val == "object") {
        this.linkedAccount = val;
      } else if (val && (typeof val == "string") && this.bankList.length > 0) {
        const objIndex = this.bankList.findIndex((obj) => obj.bankId === this.selectedAccount);
        this.linkedAccount = this.bankList[objIndex];
      }
    },

    onCancel() {
      this.$router.push({ name: "payment-request-template" });
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteTemplate();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async deleteTemplate() {
      let obj = {
        id: this.id,
        payload: { merchantId: this.partnerId, deleted: true },
      };
      this.$vs.loading();
      this.deleteRequestTemplateById(obj).then((res) => {
        this.isSaved = true;
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Request template",
          text: "Template deleted successfully",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.onCancel();
      }).finally(() => this.$vs.loading.close());
    },

    async fetchPendingPR() {
      let obj = { templateId: this.id, status: "Pending" };
      this.fetchRequestTemplateByIdandStatus(obj).then((res) => {
        const count = res.data.data.length;

        if (count > 0) {
          this.warningFound = true;
          this.warningContent = `There are ${count} pending requests linked to this
            template. By deleting this template you will also cancel any pending payment
            requests sent from this template.`;
        }

        this.popupDelActive = true;
      });
    },

    checkDirty(val) {
      this.childFormDirty = val;
    },

    changeProductConfig(val) {
      this.productConfigType = val.productConfigType;
      this.autoDebit = val.autoDebit;
      this.selectedProduct = val;

      if (!this.initialLoad) {
        this.automatedReminder = val.automatedReminder;
      }
    },

    templateConfig() {
      this.paymentOptionData.requestType = "one-off";

      if (this.templateConfigType == "pay-now" && !this.editFlag) {
        this.paymentOptionData.requestOptions = ["pay-now"];
      } else if (["direct", "pay-later"].includes(this.templateConfigType)) {
        const productConfigType = (this.templateConfigType == "pay-later") ? "THREE_PARTY" : "TWO_PARTY";
        this.defaultPlans = this.planOptions.filter((item) => item.productConfigType == productConfigType);

        if (!this.editFlag) {
          this.paymentOptionData.requestOptions = ["pay-later"];
        }
      }
    },

    async getTemplateType() {
      this.autoDebit = (this.templateConfigType == "direct");
      this.productConfigType = this.autoDebit ? "TWO_PARTY" : "THREE_PARTY";

      if (this.template && this.template.product && this.template.product.productConfigType && (this.template.product.productConfigType != this.productConfigType)) {
        this.$router.push({ name: "payment-request-template" });
      } else if (this.template && !this.template.product && this.templateConfigType != "pay-now") {
        this.$router.push({ name: "payment-request-template" });
      }
    },

    popActions(event) {
      switch (event) {
        case "leave":
          this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.splice(0, 2);
          this.$route.meta.pageTitle = "";
          this.nextObj();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },

    getInstructionText(templateType) {
      let typeText = this.id ? "Edit" : "Add";
      let configText = "";

      if (templateType == "pay-now") {
        configText = "payment request for an immediate payment via credit/debit card or EFT to your customers";
      } else if (templateType == "pay-later") {
        configText = "payment request enabled with pay-later options to your customers";
        this.instructionText = `Configure a template of preferred settings here, to apply to payment requests enabled with finance options for your customers. Where the product allows it, we'll also automatically create a hosted payment page (HPP) using these settings. Check the ${this.appName} help portal for ideas and options in how to configure your templates and use your HPP!`;
        return;
      } else if (templateType == "direct") {
        configText = `direct funding request to ${this.appName}`;
      }

      this.instructionText = `${typeText} the details below to save your preferred settings when sending ${configText} - you'll then be able to send a request with a tap of a button from your dashboard!`;
    },

    async getSavedExternalAccounts() {
      await this.fetchSavedExternalAccountsForDropdown(this.partnerId).then((response) => {
        if (response.data.data && response.data.data.length) {
          this.savedExternalAccounts = response.data.data.map((item) => {
            return {
              bankId: item._id,
              displayName: item.accountName,
              remittanceAdviceEmail: item.remittance && item.remittance.length ? item.remittance : []
            };
          });
        }
      }).catch((ex) => {
        this.showMessage(ex.response.data.title || "Saved external bank", ex.response.data.message, "danger", "icon-check-circle")
      });
    },

    changeRemittanceSettings(val) {
      this.renderRemittance = true;

      if (this.remittanceAdvice && this.remittanceAdvice.bankAccountDefault && (val == "requestUser")) {
        this.remittanceAdvice = {
          allowAdd: true,
          allowOverride: true,
          bankAccountDefault: false,
          emailAddress: []
        };
      } else if (val == "customer") {
        this.renderRemittance = false;
        this.remittanceAdvice = {
          allowAdd: false,
          allowOverride: false,
          bankAccountDefault: true,
          emailAddress: [],
        };
      }
    },

    updateWebhookUrl(data) {
      this.webhookUrl = data;
    },

    updateWebhookToken(data) {
      this.webhookToken = data;
    },

    async getMerchantUsers() {
      await this.fetchPartnerStaff(this.partnerId).then((res) => {
        this.merchantUsers = res.data.data;
        this.merchantUsers = this.merchantUsers.map((element) => ({
          ...element,
          isAuthorisedToUse: false,
        }));
      }).catch((ex) => {
        this.$vs.notify({
          title: "Users",
          text: ex.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
      });
    },

    handleUserToggle(value) {
      this.authoriseAll = value;
      this.merchantUsers = this.merchantUsers.map((item) => {
        item.isAuthorisedToUse = value;
        return item;
      });
    }
  },

  watch: {
    async "paymentOptionData.requestType"(val) {
      this.isSubmitted = false;
    },

    "errors.items"(val) {
      if (this.isSubmitted && this.errors.items.length > 0) {
        this.$refs.templateCollapse.maxHeight = "none !important";
        this.$refs.headerCollapse.maxHeight = "none !important";
      }
    },

    isFormDirty(latest) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    childFormDirty: {
      handler: (latest) => {
        if (latest) {
          localStorage.setItem("formFieldUpdated", "yes");
        }
      },
      flush: "post"
    }
  },

  async beforeCreate() {
    const templateType = this.$route.params.templateType;
    const id = this.$route.params.id;
    let title = id ? "Edit " : "New ";

    if (templateType == "pay-now") {
      title = title + "pay-now (card/EFT payments only) request template";
    } else if (templateType == "pay-later") {
      title = title + "pay-later request template";
    } else if (templateType == "direct") {
      title = title + "direct funding request template";
    }

    this.$route.meta.pageTitle = title;
    this.$route.meta.breadcrumb.push({ title: title, active: true });
    this.$emit("changeRouteTitle", title);
  },

  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.splice(0, 2);
      this.$route.meta.pageTitle = "";
      next();
    }
  },

  async mounted() {
    this.$vs.loading();
    this.checkLogin().then(async(result) => {
      this.templateConfigType = this.$route.params.templateType;
      this.planOptions = result.data.data.plans;
      this.getInstructionText(this.templateConfigType);
      this.partnerRequestOptions = result.data.data.requestOptions;
      this.getNotifications();
      this.getMerchantUsers();
      await this.getGlobalSetting();

      if (!this.id) {
        if (this.templateConfigType == "pay-now") {
          this.automatedReminder = result.data.data.automatedReminder;
        }

        if (this.templateConfigType && this.templateConfigType == "pay-now" && this.partnerRequestOptions && !this.partnerRequestOptions.includes("pay-now")) {
          this.$router.push({ name: "payment-request-template" });
        } else if (["direct", "pay-later"].includes(this.templateConfigType)) {
          if (!this.defaultPlans.length) {
            this.$router.push({ name: "payment-request-template" });
          }
        }
      }

      this.$vs.loading.close();
      this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
      this.getConnectorsList(this.partnerId);
      this.getLogos();
      this.initialLoad = false;
    }).catch((ex) => {
      sentryLog(ex);
      this.$vs.loading.close();
    });
  },
};
</script>

